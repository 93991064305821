

























































import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { useMutations } from '@u3u/vue-hooks'

interface AWVideoSlider {
  title: string
  htmltext: string
  items: {
    youtubeId: string
    picture: Picture
    htmltext: string
  }[]
}

import FlexibleSlider from '@/components/flexible/Slider.vue'

export default defineComponent({
  name: 'AWVideoSlider',
  components: {
    FlexibleSlider,
  },
  props: {
    content: {
      type: Object as PropType<AWVideoSlider>,
      required: true,
    },
  },

  setup() {
    const { SET_VIDEO: setVideo } = useMutations('ui', ['SET_VIDEO'])

    // Play button video
    const playVideo = (buttonEl: HTMLElement) => {
      const videoEl = buttonEl.querySelector('video')

      buttonEl.classList.add('video-hover')

      if (videoEl?.readyState === 4) {
        // Video is loaded, just play it
        videoEl.play()
        buttonEl.classList.add('video-playing')
      } else if (videoEl) {
        // Video is not loaded, load then play
        videoEl.addEventListener('loadeddata', () => {
          // If video is still hovered, play video
          if (buttonEl.classList.contains('video-hover')) {
            videoEl.play()
            buttonEl.classList.add('video-playing')
          }
        })

        videoEl.load()
      }
    }

    // Pause button video
    const pauseVideo = (buttonEl: HTMLElement) => {
      const videoEl = buttonEl.querySelector('video')

      if (!videoEl) {
        return
      }

      videoEl.pause()
      buttonEl.classList.remove('video-playing')
    }

    // On button click, open video popup
    const onButtonClick = (event: MouseEvent, youtubeId: string) => {
      setVideo(youtubeId)

      // If video is playing, stop it
      if (
        event.currentTarget instanceof HTMLElement &&
        event.currentTarget.classList.contains('video-playing')
      ) {
        pauseVideo(event.currentTarget)
      }
    }

    // On button mouseenter, play video
    const onButtonMouseEnter = (event: MouseEvent) => {
      event.target instanceof HTMLElement && playVideo(event.target)
    }

    // On button mouseleave, stop video
    const onButtonMouseLeave = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement) {
        event.target.classList.remove('video-hover')
        pauseVideo(event.target)
      }
    }

    return {
      onButtonClick,
      onButtonMouseEnter,
      onButtonMouseLeave,
    }
  },
})
