var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flexible-slider bordered"},[_c('div',{staticClass:"flexible-slider-inner wrapper"},[_c('GTitle',{staticClass:"flexible-slider__title h3 pt-sm",attrs:{"tag":"h2","content":_vm.content.title}}),_c('div',{directives:[{name:"aware",rawName:"v-aware"}],ref:"container",staticClass:"flexible-slider-container",on:{"viewport":_vm.onResize}},[_c('ul',{directives:[{name:"aware",rawName:"v-aware"}],ref:"list",staticClass:"flexible-slider__items",on:{"viewport":_vm.onResize}},_vm._l((_vm.content.gallery || _vm.content.items || []),function(item,i){return _c('li',{key:(((_vm.isPicture(item) && item.rawUrl) || _vm.content.title) + "-" + i),ref:"item",refInFor:true,staticClass:"flexible-slider__item"},[_vm._t("item",function(){return [_c('UiPicture',{staticClass:"flexible-slider__item__picture br-small",class:{ 'has-caption': _vm.isPicture(item) && item.caption },attrs:{"content":item,"sets":['160', '240', '320', '480', '640', '960']}})]},{"item":item})],2)}),0)]),(_vm.index > 0 || _vm.canSlide)?_c('div',{staticClass:"flexible-slider__controls"},[_c('GAction',{staticClass:"flexible-slider__controls__item--prev",attrs:{"content":{
          label: 'Précédent',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'leftArrow',
        },"prevent-tracking":true,"aria-label":"Précédent","disabled":_vm.index === 0},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_c('GAction',{staticClass:"flexible-slider__controls__item--next",attrs:{"content":{
          label: 'Suivant',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'arrow',
        },"prevent-tracking":true,"aria-label":"Suivant","disabled":!_vm.canSlide},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}}),(_vm.content.link)?_c('GAction',{staticClass:"flexible-slider__controls__item--link",attrs:{"content":Object.assign({}, _vm.content.link,
          {modifiers: ['btn']}),"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.trackCta(_vm.content.link)}}}):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }