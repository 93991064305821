















































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { FlexibleSlider, Link, Picture } from '@/inc/types'
import Slidy, { TransitionInfos } from 'epic-slidy'
import gsap from 'gsap'
import { push, pushCta, GtmLayer, GtmCta } from '@/inc/utils'

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'FlexibleSlider',
  components: { UiPicture },
  props: {
    content: {
      type: Object as () => FlexibleSlider<unknown>,
      required: true,
    },
  },
  setup(props) {
    const container = ref<HTMLElement | null>(null)
    const list = ref<HTMLElement | null>(null)
    const index = ref(0)
    const canSlide = ref(true)
    let slider: Slidy
    let isMobile = true
    let listOffset = 0

    const isPicture = (item: Picture | unknown): item is Picture =>
      Boolean((item as Picture).src)

    const prev = () => {
      slider.slidePrev('controls')

      // Push to datalayer
      const layer: GtmLayer = {
        event: 'slider',
        interactionType: 'Flèche Gauche',
      }

      push(layer)
    }

    const next = () => {
      slider.slideNext('controls')

      // Push to datalayer
      const layer: GtmLayer = {
        event: 'slider',
        interactionType: 'Flèche Droite',
      }
      push(layer)
    }

    // Track click on cta
    const trackCta = (link: Link) => {
      if (container.value) {
        const layer: Partial<GtmCta> = {
          clickText: link.label,
        }

        pushCta(layer, container.value)
      }
    }

    const onResize = () => {
      // eslint-disable-next-line no-mixed-operators
      if (isMobile !== window.innerWidth < 1024) {
        // Go back to first slide because height of slider changed
        slider.slideTo(0, 'controls', false)
        gsap.set(list.value, { x: 0 })
        index.value = 0
        canSlide.value = true
        listOffset = 0
      }

      isMobile = window.innerWidth < 1024
    }

    onMounted(() => {
      isMobile = window.innerWidth < 1024

      const transition = (
        currentSlide: HTMLElement,
        newSlide: HTMLElement,
        { currentIndex, newIndex }: TransitionInfos
      ) => {
        const tl = gsap.timeline()
        const { length } = props.content.gallery || props.content.items || []

        index.value = newIndex

        // Check if container and list els exist
        if (!container.value || !list.value) {
          return tl.then()
        }

        // Compute new offset for list
        if (newIndex > currentIndex) {
          listOffset += currentSlide.offsetWidth
        } else {
          listOffset -= newSlide.offsetWidth
        }

        // Slider can slide more if
        // 1) index is smaller than total length
        // 2) there is more to the list to slide
        canSlide.value =
          index.value < length - 1 &&
          list.value.offsetWidth - listOffset >= container.value.offsetWidth

        // If can't slide more, scroll to the end of the list
        if (!canSlide.value) {
          return tl
            .add('transition')
            .to(
              list.value,
              {
                x: container.value.clientWidth - list.value.scrollWidth,
                duration: 0.5,
              },
              'transition'
            )
            .then()
        }

        return tl
          .add('transition')
          .to(
            list.value,
            {
              x: -listOffset,
              duration: 0.5,
            },
            'transition'
          )
          .then()
      }

      slider = new Slidy(list.value as HTMLElement, {
        controls: false,
        click: false,
        drag: true,
        swipe: true,
        loop: false,
        transition,
      })

      slider.init()
    })

    onUnmounted(() => {
      if (slider) {
        slider.destroy()
      }
    })

    return {
      container,
      list,
      onResize,
      prev,
      next,
      trackCta,
      index,
      canSlide,
      isPicture,
    }
  },
})
